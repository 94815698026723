import { useEffect, useState } from "react"
import axios from "axios"
import { Container } from "reactstrap";
import { ProCard, ProForm, ProFormSelect, ProFormText, ProFormTextArea, ProSkeleton } from "@ant-design/pro-components";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Result } from "antd";

const CryptoJS = require('crypto-js');

const encrypt = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

const decrypt = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

const axs = axios.create({
    baseURL: 'http://194.233.82.95:8000/'
})

axs.interceptors.request.use((req) => {
    req.headers["Authorization"] = "Bearer ";
    req.headers["Cache-Control"] = "no-cache";
    req.headers["Pragma"] = "no-cache";
    return req;
  });

  async function postRequest(url, data, config = {}) {
    return await axs
      .post(url, data, config)
      .then((response) => response?.data);
  }

export function UpdateAddress(){

    const [form] = ProForm.useForm()
    const params = useParams()

    const [expired, setExpired] = useState(false)
    const [loading, setLoading] = useState(true)
    const [initCustomer, setCustomer] = useState()

    const [token, setToken] = useState()
    const [province, setProvince] = useState([])
    const [city, setCity] = useState([])
    const [district, setDistrict] = useState([])
    const [subdistrict, setSubdistrict] = useState([])

    const [selectedProv, setSelectedProv] = useState()
    const [selectedCity, setSelectedCity] = useState()
    const [selectedDistrict, setSelectedDistrict] = useState()
    const [selectedSubDistrict, setSelectedSubDistrict] = useState()

    const handleProvinceChanged = val => {
        setSelectedCity(undefined)
        setSelectedDistrict(undefined)
        setSelectedSubDistrict(undefined)
        form.setFieldValue('kab', undefined)
        form.setFieldValue('kec', undefined)
        form.setFieldValue('kel', undefined)
        // form.setFieldValue('poscode', undefined)

        if(val){
            setSelectedProv(val)
            postRequest('osd/api_v2/auth?user=admin&password=123', {}).then(res=>{
                postRequest('osd/api_v2/getlistcity/?token='+res.access_token+'&province='+val).then(prov => {
                    setCity(prov.map(p => ({label: p.city, value: p.city})))
                })
            })
        } else {
            setSelectedProv(undefined)
        }
    }

    const handleCityChanged = val => {
        setSelectedDistrict(undefined)
        setSelectedSubDistrict(undefined)
        form.setFieldValue('kec', undefined)
        form.setFieldValue('kel', undefined)
        // form.setFieldValue('poscode', undefined)

        if(val){
            setSelectedCity(val)
            postRequest('osd/api_v2/getlistdistrict/?token='+token+'&province='+selectedProv+'&city='+val).then(prov => {
                console.log('result city : ', prov)
                setDistrict(prov.map(p => ({label: p.district, value: p.district})))
            })
        } else {
            setSelectedCity(undefined)
        }
    }

    const handleDistricChanged = val => {
        setSelectedSubDistrict(undefined)
        form.setFieldValue('kel', undefined)
        // form.setFieldValue('poscode', undefined)

        if(val){
            setSelectedDistrict(val)
            postRequest('osd/api_v2/getlistsubdistrict/?token='+token+'&province='+selectedProv+'&city='+selectedCity+'&district='+val).then(prov => {
                setSubdistrict(prov.map(p => ({label: p.subdistrict, value: p.subdistrict})))
            })
        } else {
            setSelectedDistrict(undefined)
        }
    }

    useEffect(()=>{
        setLoading(true)
        var text = decrypt(params.nowa)
        var date = text.split('-')[0]
        var nowa = text.split('-')[1]
        
        if(moment(date).diff(moment(), "day") <= 1){
            console.log('param ',moment("20240419"))
            console.log('current', moment())
            console.log(moment("20240419").diff(moment(), "day"))
            postRequest('osd/api_v2/auth?user=admin&password=123', {}).then(res=>{
                setToken(res.access_token)
                postRequest('osd/api_v2/getlistprovince/?token='+res.access_token).then(prov => {
                    console.log('result province : ', prov)
                    setProvince(prov.map(p => ({label: p.province, value: p.province})))
                    postRequest('osd/api_v2/getCustomerbyHp?token='+res.access_token+'&nohp='+nowa).then(res => {
                        console.log('response Cust : ', res)
                        if(res.length >= 0){
                            setCustomer(res[0])
                        }
                        setLoading(false)
                    })
                })
            })
        } else {
            setLoading(false)
            setExpired(true)
        }

        
    },[])

    if(loading) return <ProSkeleton />

    if(expired) return <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
 
  />

    return <>
        <Container className="mt-5">
            <ProCard boxShadow title="Ollashopping Depok - Alamat Pembeli">
                <ProForm form={form} initialValues={initCustomer}
                onFinish={(val)=>{console.log('value : ', val)}}>
                <ProFormText disabled label="No WA" name={'nohp'} rules={[{required: true}]}></ProFormText>
                <ProFormText label="Nama" name={'namecust'} rules={[{required: true}]}></ProFormText>
                {/* <ProFormSelect label="Negara" name="country"></ProFormSelect> */}
                <ProFormSelect showSearch label="Provinsi" name="prov" options={province} rules={[{required: true}]} fieldProps={{onChange: handleProvinceChanged, value:selectedProv}} ></ProFormSelect>
                <ProFormSelect showSearch label="Kabupaten/Kota" name="kab" options={city} rules={[{required: true}]} fieldProps={{onChange: handleCityChanged, value:selectedCity}}></ProFormSelect>
                <ProFormSelect showSearch label="Kecamatan" name="kec" options={district} rules={[{required: true}]} fieldProps={{onChange: handleDistricChanged, value:selectedDistrict}}></ProFormSelect>
                <ProFormSelect showSearch label="kelurahan" name="kel" options={subdistrict} rules={[{required: true}]} fieldProps={{value:selectedSubDistrict}} ></ProFormSelect>
                {/* <ProFormText label="Kodepos" name="poscode" rules={[{required: true}]}></ProFormText> */}
                <ProFormTextArea label="Alamat/Jalan/No.Rumah" name="addresscust" rules={[{required: true}]}></ProFormTextArea>
                </ProForm>
            </ProCard>
        </Container>
    </>
}