import { create } from "zustand"
import { Button, Popconfirm, Progress, Space, notification } from "antd"
import React, { useEffect, useState } from "react"
import ListViewV2 from "../DataListV2"
import DataListStore from "store/DataListStore"
import { ProSkeleton, ProTable } from "@ant-design/pro-components"
import { getRequest, postRequest } from "services/EndPointApiService"

const uploadCommentStore = create(DataListStore)

export default function UploadedCommentIndex() {
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(true)

  const [listDaftar, setListDaftar] = useState([])
  const [selections, setSelections] = useState([])
  const [reprocessing, setReprocess] = useState(false)

  useEffect(() => {
    setLoading(true)
    setSelections([])
    setReprocess(false)
    getRequest("DataList/GetUserDaftarComments").then((res) => {
      setLoading(false)
      setListDaftar(res)
    })
  }, [refresh])

  if (loading) return <ProSkeleton type="descriptions" />

  return (
    <>
      <Space
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "rgba(255,255,255,0.9)",
          padding: "8px",
        }}
        wrap
      >
        <h3>Request Daftar Comments</h3>
        <Popconfirm
          placement="bottomRight"
          title="Reprocess Confirmation"
          description={
            <div>
              This action will insert customer and reprocess transaction.
              <br />
              Are you Sure ?
            </div>
          }
          onConfirm={(e) => {
            setReprocess(true)
            // console.log("selectedKeys : ", selections)
            postRequest("DataList/SaveCustomerFromDaftar", {
              commentIds: selections,
            }).then((res) => {
              // console.log("result success : ", res)
              setRefresh(!refresh)
              setReprocess(false)
              notification.success({
                message: "Reprocess",
                description: "Reprocess Finish",
              })
            })
          }}
        >
          <Button type="primary" size="large" loading={reprocessing}>
            Reprocess Comments
          </Button>
        </Popconfirm>
      </Space>
      {reprocessing && (
        <Progress
          showInfo={false}
          percent={99.9}
          status="active"
          strokeColor={{ from: "#108ee9", to: "#87d068" }}
        />
      )}
      <ProTable
        rowKey={"pkid"}
        defaultSize="larger"
        dataSource={listDaftar}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        scroll={{
          x: "max-content",
        }}
        columns={[
          {
            key: "fbid",
            dataIndex: "fbid",
            title: "Facebook Id",
          },
          {
            key: "fb_name",
            dataIndex: "fb_name",
            title: "Nama FB",
          },
          {
            key: "comment",
            dataIndex: "comment",
            title: "Comment",
          },
          {
            key: "post_time",
            dataIndex: "post_time",
            title: "Post Time",
          },
        ]}
        search={false}
        rowSelection={{
          preserveSelectedRowKeys: true,
          onChange: setSelections,
        }}
      />
      <ListViewV2 viewId="uploadcomments" store={uploadCommentStore} />
    </>
  )
}
