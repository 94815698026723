import axios from "axios";
import { notification } from "antd";

const OSD_BASE_URI = "http://194.233.82.95:5656/";
const API_TOKEN =
  "modosd2023-eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY4NTU1OTEwOCwiaWF0IjoxNjg1NTU5MTA4fQ.XzHEt0Uq_98S3-_dH7pX4bm5-20ISpl82INlJiS2NIc";

const OSDEndPointRequest = axios.create({
  baseURL: OSD_BASE_URI,
});

OSDEndPointRequest.interceptors.request.use((req) => {
  req.headers["X-API-Key"] = API_TOKEN;
  req.headers["Authorization"] = "Bearer ";
  req.headers["Cache-Control"] = "no-cache";
  req.headers["Pragma"] = "no-cache";
  return req;
});

export async function requestPageComment(pageId) {
  return await OSDEndPointRequest.get("fetch-data?id_page=" + pageId);
}

export const API_URL = "api/";
export const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  req.headers["X-API-Key"] = API_TOKEN;
  req.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
  req.headers["Cache-Control"] = "no-cache";
  req.headers["Pragma"] = "no-cache";
  return req;
});

async function alertSessionExpired() {
  localStorage.clear();
  alert("Session expired, please re-login");
  window.location.href = "/authentication/login";
}

async function handleError(error) {
  var message = "Whops! something went wrong, please contact our support team.";
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 404:
        notification.error({
          message: "Error",
          description:
            "Sorry! the page you are looking for could not be found.",
        });
        break;
      case 500:
        notification.error({
          message: "Error",
          description:
            "Sorry! something went wrong, please contact our support team.",
        });
        break;
      case 401:
        message = "Invalid credentials";
        alertSessionExpired();
        break;
      case 400:
        message = error.response.data;
        notification.error({
          message: "Bad Request",
          description: message,
        });
        break;
      default:
        message = error[1];
        notification.error({
          message: "Error",
          description: message,
        });
        break;
    }
  } else {
    message = error.message;
  }
  return {
    errorStatus: error?.response?.status || -1,
    errorMessage: message,
  };
}

axiosApi.interceptors.response.use((response) => response, handleError);

export async function getRequest(url, config = {}) {
  return await axiosApi.get(url, config).then((response) => response?.data);
}

export async function postRequest(url, data, config = {}) {
  return await axiosApi
    .post(url, data, config)
    .then((response) => response?.data);
}

export async function postFile(url, formData) {
  // if (url.indexOf("reportbc") !== -1) {
  //   const api = axios.create({
  //     baseURL: "http://207.180.243.101:8000/reactor_api/",
  //   });
  //   return await api.post("File/UploadReportBC", formData);
  // } else
  return await axiosApi.post(url, formData);
}
