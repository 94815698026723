import React from "react";
import { create } from "zustand";
import DataListStore from "../../store/DataListStore";
import ListViewV2 from "../App/DataListV2";

const customerDataStore = create(DataListStore);

const CustomerList = (props) => {
  return (
    <ListViewV2
      viewId="customers"
      store={customerDataStore}
      showCustomAction
      hideEdit
    />
  );
};

export default CustomerList;
