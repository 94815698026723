import {
  ProCard,
  ProForm,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components"
import { notification, Space } from "antd"
import React, { useEffect, useState } from "react"
import {
  GetMenuAccessList,
  RegisterUser,
} from "../../../services/AdministrativeService"
import ListViewV2 from "../../App/DataListV2"

const UserManagement = () => {
  const processSubmit = async (values) => {
    let saveResult = await RegisterUser(values)
    if (saveResult.status === "Success") {
      notification.success({
        message: "Register User",
        description: saveResult.message,
      })
    } else {
      notification.error({
        message: "Register User",
        description: saveResult.message,
      })
    }
    return true
  }

  const [menuAccessList, setMenuAccessList] = useState([])

  useEffect(() => {
    const requestMenuAccess = async () => {
      const _accessList = await GetMenuAccessList()
      const _menuAccessList = _accessList.map((ma) => ({
        value: ma.appId,
        label: ma.accessName,
      }))
      setMenuAccessList(_menuAccessList)
    }
    requestMenuAccess()
  }, [])

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ProCard
        bordered
        hoverable
        collapsible
        defaultCollapsed
        headerBordered
        title={"Add New User"}
      >
        <ProForm
          grid
          initialValues={{
            superAdmin: false,
            active: true,
          }}
          submitter={{
            submitButtonProps: {
              size: "large",
            },
            resetButtonProps: {
              size: "large",
            },
          }}
          onFinish={processSubmit}
        >
          <ProFormText
            fieldProps={{
              size: "large",
            }}
            colProps={{ md: 12 }}
            name="firstName"
            label="First Name"
            rules={[{ required: true }]}
          />
          <ProFormText
            fieldProps={{
              size: "large",
            }}
            colProps={{ md: 12 }}
            name="lastName"
            label="Last Name"
            rules={[{ required: true }]}
          />
          <ProFormText
            fieldProps={{
              size: "large",
            }}
            colProps={{ md: 12 }}
            name="userName"
            label="Username"
            rules={[
              { required: true },
              { min: 4 },
              {
                pattern: "^[a-zA-Z0-9]*$",
                message: "Only allowed characters and numbers",
              },
            ]}
          />
          <ProFormSelect
            fieldProps={{
              size: "large",
            }}
            colProps={{ md: 12 }}
            name="menuAccessId"
            label="Menu Access"
            options={menuAccessList}
            rules={[{ required: true }]}
          />
          <ProFormText.Password
            fieldProps={{
              size: "large",
            }}
            hasFeedback
            colProps={{ md: 12 }}
            name="password"
            label="Password"
            rules={[
              { required: true },
              { min: 8, message: "Minimum 8 character" },
            ]}
          />
          <ProFormText.Password
            fieldProps={{
              size: "large",
            }}
            hasFeedback
            colProps={{ md: 12 }}
            name="confirm"
            label="Confirm Password"
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  )
                },
              }),
            ]}
          />
          <ProFormSwitch
            colProps={{ md: 6 }}
            name="superAdmin"
            label="Super Admin Previlage"
          />
          <ProFormSwitch colProps={{ md: 6 }} name="active" label="Is Active" />
        </ProForm>
      </ProCard>
      <ListViewV2 viewId="users" />
    </Space>
  )
}

export default UserManagement
