import { ProCard, ProForm, ProFormList } from "@ant-design/pro-components"
import { FilterOutlined } from "@ant-design/icons"
import { Space } from "antd"
import React from "react"
import FilterItem from "./FilterItem"

const AdvancedFilterBuilder = (props) => {
  return (
    <ProCard
      bordered
      headerBordered
      defaultCollapsed
      collapsible
      title={
        <Space>
          <span>Show Advanced Filters</span>
          <FilterOutlined />
        </Space>
      }
      headStyle={{ backgroundColor: "#F4F4F4" }}
      bodyStyle={{ width: "100%" }}
    >
      <ProForm
        layout="vertical"
        onFinish={props.onFinish}
        onReset={props.onReset}
        submitter={{
          submitButtonProps: {
            title: "Apply Filter",
            size: "large",
          },
          resetButtonProps: {
            danger: true,
            type: "text",
            size: "large",
          },
        }}
      >
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <ProFormList
            name="filters"
            creatorButtonProps={{
              type: "primary",
              size: "large",
              creatorButtonText: (
                <Space>
                  <FilterOutlined /> Add Filter
                </Space>
              ),
              icon: false,
            }}
          >
            <FilterItem columns={props.columns} />
          </ProFormList>
        </div>
        <br />
      </ProForm>
    </ProCard>
  )
}

export default AdvancedFilterBuilder
