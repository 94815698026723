import { getRequest, postRequest } from "./EndPointApiService";

const AdministrativeAPI = "Administrative";

export async function AddMenuAccess(data) {
  return postRequest(AdministrativeAPI + "/AddMenuAccess", data);
}

export async function AddSpecialPermissionOnly(data) {
  return postRequest(AdministrativeAPI + "/AddSpecialPermissionOnly", data);
}

export async function GetMenuList() {
  return getRequest(AdministrativeAPI + "/GetMenuList");
}

export async function GetMenuAccessList() {
  return getRequest(AdministrativeAPI + "/GetMenuAccessList");
}

export async function RegisterUser(data) {
  return postRequest(AdministrativeAPI + "/RegisterUser", data);
}

export async function GetUnknownCustomerList() {
  return getRequest(AdministrativeAPI + "/GetUnknownCustomerList");
}

export async function AddCustomerFromUnknown(data) {
  return postRequest(AdministrativeAPI + "/AddCustomerFromUnknown", data);
}
