import {
  ProCard,
  ProForm,
  ProFormList,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components"
import { notification, Space } from "antd"
import React, { useEffect, useState } from "react"
import { create } from "zustand"
import {
  AddMenuAccess,
  AddSpecialPermissionOnly,
  GetMenuList,
} from "../../../services/AdministrativeService"
import DataListStore from "../../../store/DataListStore"
import ListViewV2 from "../../App/DataListV2"
import { GetMenuAccessList } from "../../../services/AdministrativeService"

const useAccessDataStore = create(DataListStore)
const usePermissionDataStore = create(DataListStore)

const MenuAccess = () => {
  const processSubmit = async (values) => {
    let saveResult = await AddMenuAccess(values)
    if (saveResult.status === "Success") {
      notification.success({
        message: "Save Menu Access",
        description: saveResult.message,
      })
    } else {
      notification.error({
        message: "Save Menu Access",
        description: saveResult.message,
      })
    }
    return true
  }
  const processSubmitAccess = async (values) => {
    let saveResult = await AddSpecialPermissionOnly(values)
    if (saveResult.status === "Success") {
      notification.success({
        message: "Save Special Permission",
        description: saveResult.message,
      })
    } else {
      notification.error({
        message: "Save Special Permission",
        description: saveResult.message,
      })
    }
    return true
  }

  const [menuList, setMenuList] = useState([])
  const [menuAccessList, setMenuAccessList] = useState([])

  useEffect(() => {
    const requestList = async () => {
      const _list = await GetMenuList()
      const _accessList = await GetMenuAccessList()

      const _menuList = _list.map((ml) => ({
        value: ml.menuId,
        label: ml.menuName,
      }))
      const _menuAccessList = _accessList.map((ml) => ({
        value: ml.accessName,
        label: ml.accessName,
      }))

      setMenuList(_menuList)
      setMenuAccessList(_menuAccessList)
    }

    requestList()
  }, [])

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ProCard
        bordered
        hoverable
        collapsible
        defaultCollapsed
        headerBordered
        title={"Add New Rule Access"}
      >
        <ProForm
          initialValues={{
            accessName: "",
            allowAll: true,
            blockAll: false,
            specialPermissions: [],
          }}
          submitter={{
            submitButtonProps: {
              size: "large",
            },
            resetButtonProps: {
              size: "large",
            },
          }}
          onFinish={processSubmit}
        >
          <ProFormText
            fieldProps={{
              size: "large",
            }}
            name="accessName"
            label="Access Rule Name"
            rules={[{ required: true }]}
          />
          <Space size="large" style={{ width: "100%" }} wrap>
            <ProFormSwitch
              name="allowAll"
              label="Allow All View"
              tooltip="Set 'Allow' all view by default. Add 'Block' type of special permission for hiding selected views."
            />
            <ProFormSwitch
              name="blockAll"
              label="Block All View"
              tooltip="Set 'Block' all view by default. Add 'Allow' type of special permission for showing selected views."
            />
          </Space>
          <Space size="large" style={{ width: "100%" }} wrap>
            <ProFormSwitch name="canAdd" label="Can Add" />
            <ProFormSwitch name="canEdit" label="Can Edit" />
            <ProFormSwitch name="canDelete" label="Can Delete" />
            <ProFormSwitch name="canExport" label="Can Export" />
            <ProFormSwitch name="canUpload" label="Can Upload" />
            <ProFormSwitch name="canProcess" label="Can Process" />
          </Space>
          <ProFormList
            name={"specialPermissions"}
            label="Special Permissions"
            tooltip="Use this special type of permission to override access of individual menu."
            creatorButtonProps={{
              type: "primary",
              size: "large",
              creatorButtonText: <Space>Add Special Permission</Space>,
              icon: false,
            }}
          >
            <Space size="large" style={{ width: "100%" }} wrap>
              <ProFormSelect
                showSearch
                fieldProps={{
                  size: "large",
                }}
                width={240}
                name="menuId"
                label="Menu Id"
                options={menuList}
                rules={[{ required: true }]}
              />
              <ProFormSelect
                fieldProps={{
                  size: "large",
                }}
                name="permissionType"
                label="Permission"
                options={[
                  { value: "BLOCK", label: "Block" },
                  { value: "ALLOW", label: "Allow" },
                ]}
                rules={[{ required: true }]}
              />
              <ProFormSwitch name="canAdd" label="Add" />
              <ProFormSwitch name="canEdit" label="Edit" />
              <ProFormSwitch name="canDelete" label="Delete" />
              <ProFormSwitch name="canExport" label="Export" />
            </Space>
          </ProFormList>
        </ProForm>
      </ProCard>
      <ListViewV2 viewId="access" store={useAccessDataStore} />
      <ProCard
        bordered
        hoverable
        collapsible
        defaultCollapsed
        headerBordered
        title={"Add Special Permission"}
      >
        <ProForm
          initialValues={{
            accessName: "",
            specialPermissions: [],
          }}
          submitter={{
            submitButtonProps: {
              size: "large",
            },
            resetButtonProps: {
              size: "large",
            },
          }}
          onFinish={processSubmitAccess}
        >
          <ProFormSelect
            fieldProps={{
              size: "large",
            }}
            name="accessName"
            label="Access Rule Name"
            options={menuAccessList}
            rules={[{ required: true }]}
          />
          <ProFormList
            name={"specialPermissions"}
            label="Special Permissions"
            tooltip="Use this special type of permission to override access of individual menu."
            creatorButtonProps={{
              type: "primary",
              size: "large",
              creatorButtonText: <Space>Add Special Permission</Space>,
              icon: false,
            }}
          >
            <Space size="large" style={{ width: "100%" }} wrap>
              <ProFormSelect
                fieldProps={{
                  size: "large",
                }}
                showSearch
                width={240}
                name="menuId"
                label="Menu Id"
                options={menuList}
                rules={[{ required: true }]}
              />
              <ProFormSelect
                fieldProps={{
                  size: "large",
                }}
                name="permissionType"
                label="Permission"
                options={[
                  { value: "BLOCK", label: "Block" },
                  { value: "ALLOW", label: "Allow" },
                ]}
                rules={[{ required: true }]}
              />
              <ProFormSwitch name="canAdd" label="Add" />
              <ProFormSwitch name="canEdit" label="Edit" />
              <ProFormSwitch name="canDelete" label="Delete" />
              <ProFormSwitch name="canExport" label="Export" />
            </Space>
          </ProFormList>
        </ProForm>
      </ProCard>
      <ListViewV2 viewId="permission" store={usePermissionDataStore} />
    </Space>
  )
}

export default MenuAccess
