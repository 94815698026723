import React from "react"
import { ProForm, ProFormField } from "@ant-design/pro-components"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
} from "reactstrap"

function DefaultLoginCard(props) {
  const [emailFocus, setEmailFocus] = React.useState("")
  const [passwordFocus, setPasswordFocus] = React.useState("")

  const [formRef] = ProForm.useForm()
  return (
    <>
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-white pb-5">
          <div className="text-center mb-4">Login</div>
          <div className="text-center text-muted">Olla Shopping Depok</div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          <ProForm submitter={false} form={formRef} onFinish={props.login}>
            <ProFormField name={"username"} rules={[{ required: true }]}>
              <FormGroup className={"mb-3 " + emailFocus}>
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-single-02"></i>
                  </InputGroupText>
                  <Input
                    name="username"
                    placeholder="username"
                    onFocus={() => setEmailFocus("focused")}
                    onBlur={() => setEmailFocus("")}
                  />
                </InputGroup>
              </FormGroup>
            </ProFormField>
            <ProFormField name={"password"} rules={[{ required: true }]}>
              <FormGroup className={passwordFocus}>
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open"></i>
                  </InputGroupText>
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    onFocus={() => setPasswordFocus("focused")}
                    onBlur={() => setPasswordFocus("")}
                  ></Input>
                </InputGroup>
              </FormGroup>
            </ProFormField>
            <div className="text-center">
              <Button
                disabled={props.loading}
                className="my-4"
                color="primary"
                type="submit"
              >
                Login
              </Button>
            </div>
          </ProForm>
        </CardBody>
      </Card>
    </>
  )
}

export default DefaultLoginCard
