import { getRequest, postRequest } from "./EndPointApiService";

const AuthenticationOrigin = "Authentication";
export const AuthenticationUrl = "authentication/login";

export async function Login(userForm) {
  let loginResult = await postRequest(AuthenticationOrigin + "/Auth", userForm);
  if (loginResult) {
    localStorage.setItem("auth-id", JSON.stringify(loginResult));
    localStorage.setItem("token", loginResult.token);

    let getMenuUrl = AuthenticationOrigin + "/GetUserMenu/";
    var userMenu = await getRequest(getMenuUrl + userForm.username);
    localStorage.setItem("user-menu", JSON.stringify(userMenu));
    return true;
  }

  return false;
}
