import { Result } from "antd"
import React from "react"
import { Button } from "reactstrap"

const logout = () => {
  localStorage.clear()

  return (
    <>
      <Result
        style={{
          height: "100vh",
          backgroundImage: "url(" + require("assets/img/ill/ill.png") + ")",
        }}
        status={"success"}
        title="Logged out"
        subTitle="You have successfully log out."
        extra={
          <Button href="/" color="primary">
            Back to landing page
          </Button>
        }
      />
    </>
  )
}

export default logout
