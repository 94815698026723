import {
  ProFormDatePicker,
  ProFormDateRangePicker,
  ProFormDigit,
  ProFormDigitRange,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components"
import { Space } from "antd"
import React, { useEffect, useState } from "react"

function GetOperator(columnType) {
  switch (columnType) {
    case "date":
    case "datetime":
    case "datetime2":
      return [
        { label: "Equals", value: "EQUALS" },
        { label: "Not Equals", value: "NOTEQUALS" },
        { label: "Less Than", value: "LESSTHAN" },
        { label: "Greater Than", value: "GREATERTHAN" },
        { label: "Between", value: "BETWEEN" },
      ]
    case "bigint":
    case "tinyint":
    case "int":
    case "smallint":
    case "float":
    case "decimal":
      return [
        { label: "Equals", value: "EQUALS" },
        { label: "Not Equals", value: "NOTEQUALS" },
        { label: "Less Than", value: "LESSTHAN" },
        { label: "Greater Than", value: "GREATERTHAN" },
        { label: "Between", value: "BETWEEN" },
      ]
    case "bit":
      return [
        { label: "Equals", value: "EQUALS" },
        { label: "Not Equals", value: "NOTEQUALS" },
      ]
    default:
      return [
        { label: "Equals", value: "EQUALS" },
        { label: "Not Equals", value: "NOTEQUALS" },
        { label: "Like", value: "LIKE" },
        { label: "Not Like", value: "NOTLIKE" },
        { label: "Include", value: "INCLUDE" },
        { label: "Not Include", value: "NOTINCLUDE" },
      ]
  }
}

function GetFilterValueDom(selectedColumn, operator) {
  const type = selectedColumn.columType
  switch (type) {
    case "date":
    case "datetime":
    case "datetime2":
      switch (operator) {
        case "BETWEEN":
          console.log("operator in between")
          return (
            <ProFormDateRangePicker
              fieldProps={{
                size: "large",
              }}
              width="md"
              name="filterValue"
              label="Value"
              rules={[
                {
                  required: true,
                  message: "please fill all field. or remove if unnecessary",
                },
              ]}
              disabled={operator == null || type == null}
            />
          )
        default:
          return (
            <ProFormDatePicker
              fieldProps={{
                size: "large",
              }}
              width="md"
              name="filterValue"
              label="Value"
              rules={[
                {
                  required: true,
                  message: "please fill all field. or remove if unnecessary",
                },
              ]}
              disabled={operator == null || type == null}
            />
          )
      }
    case "bigint":
    case "tinyint":
    case "int":
    case "smallint":
    case "float":
    case "decimal":
      switch (operator) {
        case "BETWEEN":
          return (
            <ProFormDigitRange
              fieldProps={{
                size: "large",
              }}
              width="md"
              name="filterValue"
              label="Value"
              rules={[
                {
                  required: true,
                  message: "please fill all field. or remove if unnecessary",
                },
              ]}
              disabled={operator == null || type == null}
            />
          )
        default:
          return (
            <ProFormDigit
              fieldProps={{
                size: "large",
              }}
              width="md"
              name="filterValue"
              label="Value"
              rules={[
                {
                  required: true,
                  message: "please fill all field. or remove if unnecessary",
                },
              ]}
              disabled={operator == null || type == null}
            />
          )
      }
    case "bit":
      return (
        <ProFormSwitch
          fieldProps={{
            size: "large",
          }}
          width="md"
          name="filterValue"
          label="Value"
          rules={[
            {
              required: true,
              message: "please fill all field. or remove if unnecessary",
            },
          ]}
          disabled={operator == null || type == null}
        />
      )
    default:
      return (
        <ProFormText
          fieldProps={{
            size: "large",
          }}
          width="md"
          name="filterValue"
          label="Value"
          rules={[
            {
              required: true,
              message: "please fill all field. or remove if unnecessary",
            },
          ]}
          disabled={operator === null || type == null}
        />
      )
  }
}

const FilterItem = (props) => {
  const [selectedColumn, setSelectedColumn] = useState({})
  const [selectedOperator, setSelectedOperator] = useState(null)

  const [valueNode, setValueNode] = useState(GetFilterValueDom({}, null))

  useEffect(() => {
    let newValueNode = GetFilterValueDom(selectedColumn, selectedOperator)
    setValueNode(newValueNode)
  }, [selectedColumn, selectedOperator])

  return (
    <Space>
      <ProFormSelect
        width={"md"}
        mode="single"
        name="filterField"
        label="Field Name"
        rules={[
          {
            required: true,
            message: "please fill all field. or remove if unnecessary",
          },
        ]}
        fieldProps={{
          size: "large",
          onChange: (val) => {
            setSelectedColumn(
              props.columns.find((c) => c.columnField === val) || {}
            )
          },
        }}
        options={props.columns.map((c) => ({
          value: c.columnField,
          label: c.columnLabel,
        }))}
      />
      <ProFormSelect
        width={"sm"}
        mode="single"
        name="filterOperator"
        label="Filter Operator"
        rules={[
          {
            required: true,
            message: "please fill all field. or remove if unnecessary",
          },
        ]}
        fieldProps={{
          size: "large",
          onChange: setSelectedOperator,
        }}
        options={GetOperator(selectedColumn.columType)}
      />
      {valueNode && valueNode}
    </Space>
  )
}

export default FilterItem
