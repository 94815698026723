import { Dropdown, Space } from "antd"
import React from "react"

import { FileExcelOutlined, FileTextOutlined } from "@ant-design/icons"
import { getRequest, postRequest } from "../../services/EndPointApiService"

function downloadFile(viewId, params) {
  return async (event) => {
    const fileType = event.key
    let endpointUrl = `DataList/RequestFile/${viewId}/${fileType}`
    console.log("endpoint : ", endpointUrl)

    let resultDownload = await postRequest(endpointUrl, params, {
      observe: "response",
      responseType: "blob",
    })

    const a = document.createElement("a")
    a.setAttribute("style", "display:none;")
    document.body.appendChild(a)
    const file = new Blob([resultDownload])
    let fileName = viewId.toUpperCase()
    let ext = fileType === "EXCEL" ? ".xlsx" : ".csv"
    a.download = fileName + ext
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, fileName)
    } else {
      a.href = URL.createObjectURL(file)
    }
    a.target = "_blank"
    a.click()
    document.body.removeChild(a)
  }

  // return async () => {

  // }
}

const supportedItems = [
  {
    key: "EXCEL",
    label: (
      <Space>
        <FileExcelOutlined />
        Excel File
      </Space>
    ),
  },
  {
    key: "CSV",
    label: (
      <Space>
        <FileTextOutlined />
        CSV File
      </Space>
    ),
  },
]

const DownloadButton = (props) => {
  return (
    <Dropdown.Button
      disabled={props.disabled}
      menu={{
        items: supportedItems,
        onClick: downloadFile(props.viewId, props.params),
      }}
    >
      Download File
    </Dropdown.Button>
  )
}

export default DownloadButton
