import {
  ProForm,
  ProFormDigit,
  ProFormList,
  ProFormSwitch,
  ProFormText,
  ProSkeleton,
  ProTable,
} from "@ant-design/pro-components"
import {
  SettingOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  CloseOutlined,
  PhoneOutlined,
  CheckOutlined
} from "@ant-design/icons"
import { Button, Drawer, Modal, notification, Popconfirm, Space } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import DownloadButton from "../../components/controll-button/DownloadButton"
import DataListStore from "../../store/DataListStore"
import moment from "moment/moment"
import {
  DeleteRecords,
  DeleteTransaction,
  EditRecords,
  UpdateViewSetting,
  UpdateWACustomer,
} from "../../services/datalist/datalist-api"
import AdvancedFilterBuilder from "../../components/filter-builder/Filterbuilder"
import ApplicationStore from "../../store/ApplicationStore"
import { create } from "zustand"
import { getRequest } from "../../services/EndPointApiService"

function TypeProperties(column) {
  const type = column.columType
  switch (type) {
    case "date":
      return {
        valueType: "date",
        render: (prop, rec) => {
          var newDate = moment(new Date(rec[column.columnField]))
          return <>{newDate.format("YYYY-MM-DD")}</>
        },
      }
    case "datetime":
    case "datetime2":
      return {
        valueType: "dateTime",
        render: (prop, rec) => {
          var newDate = moment(new Date(rec[column.columnField]))
          return <>{newDate.format("YYYY-MM-DD HH:mm:SS")}</>
        },
      }
    case "bigint":
    case "tinyint":
    case "int":
    case "smallint":
    case "float":
    case "decimal":
      return {
        valueType: "digit",
        align: "right",
      }
    case "bit":
      return {
        valueType: "switch",
        align: "center",
        render: (prop, rec) => {
          return rec[column.columnField] ? (
            <i style={{ color: "#75D63A" }} className="icofont-check"></i>
          ) : (
            <i style={{ color: "#D6753A" }} className="icofont-close"></i>
          )
        },
      }
    default:
      return {
        valueType: "text",
      }
  }
}

async function requestCustomerLevel() {
  let data = await getRequest("DataList/GetCustomerLevels")
  return data.map((d) => ({
    value: d.level,
    label: d.descriptions,
  }))
}

function MapColumns(columns, viewId) {
  if (viewId === "customers")
    return columns.map((c) => ({
      key: c.columnField,
      title: c.columnLabel,
      dataIndex: c.columnField,
      sorter: true,
      ...TypeProperties(c),
      ...(c.columnField === "customer_level"
        ? { valueType: "select", request: requestCustomerLevel }
        : {}),
    }))
  else
    return columns.map((c) => ({
      key: c.columnField,
      title: c.columnLabel,
      dataIndex: c.columnField,
      sorter: true,
      ...TypeProperties(c),
    }))
}

const useDataList = create(DataListStore)
const ListViewV2 = (props) => {
  const params = useParams()
  const useDataListStore = props.store ?? useDataList
  const viewId = params.viewId ?? props.viewId

  const formRef = useRef()
  const actionRef = useRef()

  const userAuth = ApplicationStore((state) => state.userAuth)
  const currentMenu = ApplicationStore((state) => state.currentMenu)
  const [reloader, setReloader] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)

  const loading = useDataListStore((state) => state.loading)
  const editKeys = useDataListStore((state) => state.currentEditableKeys)
  const setEditableKeys = useDataListStore((state) => state.setEditableKeys)
  const dataState = useDataListStore((state) => state.dataListState)
  const visibleColumns = useDataListStore((state) => state.visibleColumns)
  const initState = useDataListStore((state) => state.initState)

  const requestData = useDataListStore((state) => state.requestData)
  const dataListParam = useDataListStore((state) => state.dataListParam)
  const updateFilters = useDataListStore((state) => state.updateFilters)
  const currentFilters = useDataListStore((state) => state.currentFilters)
  const updateKeyword = useDataListStore((state) => state.updateKeyword)
  const currentKeyword = useDataListStore((state) => state.currentKeyword)

  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const numberFormRef = useRef()

  useEffect(() => {
    if (viewId) initState(viewId, props.filters)
    setOpenModal(false)
  }, [viewId, reloader, props.filters, props.reloader])

  if (!viewId) return <></>
  if (loading) return <ProSkeleton type="descriptions" />

  let allowAdd = false
  let allowEdit = false
  let allowDelete = false
  let allowExport = false

  if (userAuth.superAdmin) {
    allowAdd = dataState.viewInfo.canAdd
    allowEdit = dataState.viewInfo.canEdit
    allowDelete = dataState.viewInfo.canDelete
    allowExport = dataState.viewInfo.canExport
  } else {
    allowAdd = dataState.viewInfo.canAdd
    allowEdit = dataState.viewInfo.canEdit
    allowDelete = dataState.viewInfo.canDelete
    allowExport = dataState.viewInfo.canExport

    //validate special permission here...
    if (userAuth.specialPermission.length > 0) {
      let permission = userAuth.specialPermission.find(
        (sp) => sp.menuId === currentMenu.key
      )
      if (permission && permission.permissionType === "BLOCK") {
        allowAdd = !permission.canAdd
        allowEdit = !permission.canEdit
        allowDelete = !permission.canDelete
        allowExport = !permission.canExport
      } else if (permission && permission.permissionType === "ALLOW") {
        allowAdd = permission.canAdd
        allowEdit = permission.canEdit
        allowDelete = permission.canDelete
        allowExport = permission.canExport
      }
    }
  }

  return (
    <>
      <Modal
        destroyOnClose
        title="Update No WA Customer"
        open={openModal}
        onCancel={() => {
          setOpenModal(false)
        }}
        onOk={() => {
          numberFormRef.current.submit()
        }}
      >
        <ProForm
          layout="horizontal"
          formRef={numberFormRef}
          submitter={false}
          onFinish={async (val) => {
            await UpdateWACustomer(val.fbid, val.contact_number)
            setReloader(!reloader)
          }}
          initialValues={selectedCustomer}
        >
          <ProFormText
            fieldProps={{
              size: "large",
            }}
            name={"fbid"}
            label="Customer ID"
            readonly
          />
          <ProFormText
            fieldProps={{
              size: "large",
            }}
            name={"name"}
            label="Nama Customer"
            readonly
          />
          <ProFormText
            fieldProps={{
              size: "large",
            }}
            name={"contact_number"}
            label="Contact Number"
            rules={[{ required: true }]}
          />
        </ProForm>
      </Modal>
      <ProTable
        rowKey={"key"}
        search={false}
        formRef={formRef}
        actionRef={actionRef}
        defaultSize="large"
        scroll={{ x: "max-content" }}
        columns={[
          {
            fixed: true,
            title: "Actions",
            valueType: "option",
            align: "center",
            width: "80px",
            hideInForm: true,
            hideInSearch: true,
            hideInSetting: true,
            hideInDescriptions: true,
            hideInTable: !allowEdit,
            render: (_, row) => (
              <Space>
                {!props.hideEdit && (
                  <EditOutlined
                    title="Edit Record"
                    key="edit"
                    style={{ color: "#1A56A6" }}
                    onClick={() => {
                      setEditableKeys([row.key])
                    }}
                  />
                )}
                {viewId === "rekapsalesheader" && (
                  <>
                    <Popconfirm
                      title="Are you sure to delete this transaction?"
                      onConfirm={async () => {
                        let result = await DeleteTransaction(row.SalesSeasonID)
                        if (result === "OK") {
                          setReloader(!reloader)
                          notification.success({
                            message: "Delete Transaction",
                            description: "Transaction Deleted Successfully",
                          })
                        }
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined
                        title="Delete related transaction"
                        key="delete"
                        style={{ color: "#A6561A" }}
                      />
                    </Popconfirm>
                  </>
                )}
                {props.showCustomAction && (
                  <>
                    <PhoneOutlined
                      title="Change Number"
                      key="change"
                      style={{ color: "#1A56A6" }}
                      onClick={() => {
                        setSelectedCustomer(row)
                        setOpenModal(true)
                        //setEditableKeys([row.key]);
                      }}
                    />
                  </>
                )}
                {props.showUpdateAction && (
                  <>
                    {row.Status !== 'LUNAS' && 
                    <Popconfirm 
                    onConfirm={()=>{
                      props.onUpdateAction(row)
                    }}
                    title="Ubah status transaksi menjadi LUNAS ?"
                    
                    >
                    <CheckOutlined
                      title="Pembayaran Lunas"
                      key="change"
                      style={{ color: "#1A56A6" }}
                      onClick={() => {
                        
                      }}
                    />
                      </Popconfirm>}
                  </>
                )}
              </Space>
            ),
          },
          ...MapColumns(visibleColumns(), viewId),
        ]}
        params={{ currentKeyword: currentKeyword, filter: currentFilters }}
        request={requestData}
        rowSelection={
          allowDelete
            ? {
                preserveSelectedRowKeys: true,
                fixed: true,
              }
            : false
        }
        toolbar={{
          search: {
            allowClear: true,
            placeholder: "search text",
            size: "large",
            style: {
              width: "280px",
            },
          },
          onSearch: updateKeyword,
        }}
        options={{
          reload: true,
          density: true,
          search: true,
          fullScreen: true,
        }}
        pagination={{
          defaultPageSize: 10,
          defaultCurrent: 1,
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        // cardProps={{
        //   bodyStyle: {

        //   },
        // }}
        tableExtraRender={() => {
          return (
            <React.Fragment>
              <Space>
                <h1>{dataState.viewInfo.viewTitle}</h1>
                {userAuth.superAdmin && (
                  <Button
                    title="Display Settings"
                    type="link"
                    onClick={() => {
                      setOpenDrawer(true)
                    }}
                  >
                    <Space>
                      <SettingOutlined />
                    </Space>
                  </Button>
                )}
              </Space>

              {!props.hideFilter && (
                <AdvancedFilterBuilder
                  columns={visibleColumns()}
                  onFinish={updateFilters}
                  onReset={() => {
                    updateFilters([])
                  }}
                />
              )}

              {openDrawer && (
                <Drawer
                  title="View Table Display Setting"
                  width={"85vw"}
                  height="100vh"
                  onClose={() => {
                    setOpenDrawer(false)
                  }}
                  open={openDrawer}
                  bodyStyle={{ paddingBottom: 80 }}
                >
                  <ProForm
                    onFinish={async (val) => {
                      await UpdateViewSetting(val.viewId, val)
                      setOpenDrawer(false)
                      setReloader(!reloader)
                      return true
                    }}
                    initialValues={{
                      ...dataState.viewInfo,
                      viewColumns: dataState.columns,
                    }}
                  >
                    <ProFormDigit
                      fieldProps={{
                        size: "large",
                      }}
                      name="appId"
                      hidden
                    />
                    <ProFormText
                      fieldProps={{
                        size: "large",
                      }}
                      name={"viewId"}
                      label="View Id"
                      hidden
                    />
                    <ProFormText
                      fieldProps={{
                        size: "large",
                      }}
                      name={"tableName"}
                      label="Table Name"
                      readonly
                    />
                    <Space style={{ width: "100%" }}>
                      <ProFormSwitch width="sm" name="canAdd" label="Can Add" />
                      <ProFormSwitch
                        width="sm"
                        name="canEdit"
                        label="Can Edit"
                      />
                      <ProFormSwitch
                        width="sm"
                        name="canDelete"
                        label="Can Delete"
                      />
                      <ProFormSwitch
                        width="sm"
                        name="canExport"
                        label="Can Export"
                      />
                    </Space>

                    <ProFormText
                      fieldProps={{
                        size: "large",
                      }}
                      name={"viewTitle"}
                      label="View Title"
                      rules={[
                        { required: true, message: "please enter title" },
                      ]}
                    />
                    <Space style={{ width: "100%", overflowX: "scroll" }}>
                      <ProFormList
                        name={"viewColumns"}
                        label="Column Property"
                        actionRender={() => <></>}
                        creatorButtonProps={false}
                        required
                      >
                        <Space style={{ width: "100%" }}>
                          <ProFormDigit
                            fieldProps={{
                              size: "large",
                            }}
                            key="appId"
                            name="appId"
                            hidden
                          />
                          <ProFormText
                            fieldProps={{
                              size: "large",
                            }}
                            key="viewId"
                            name="viewId"
                            hidden
                          />
                          <ProFormText
                            fieldProps={{
                              size: "large",
                            }}
                            width={"sm"}
                            key="columnField"
                            name="columnField"
                            label="Column Field"
                            disabled
                          />
                          <ProFormText
                            fieldProps={{
                              size: "large",
                            }}
                            width={"sm"}
                            key="columnLabel"
                            name="columnLabel"
                            label="Column Label"
                            rules={[
                              {
                                required: true,
                                message: "Please fill column label",
                              },
                            ]}
                          />
                          <ProFormDigit
                            fieldProps={{
                              size: "large",
                            }}
                            width={"sm"}
                            key="columnOrder"
                            name="columnOrder"
                            label="Column Order"
                          />
                          <ProFormDigit
                            fieldProps={{
                              size: "large",
                            }}
                            width={"sm"}
                            key="maximumLength"
                            name="maximumLength"
                            label="Max Length"
                          />
                          <ProFormSwitch
                            width={"sm"}
                            key="showInList"
                            name="showInList"
                            label="Show"
                          />
                          <ProFormSwitch
                            width={"sm"}
                            key="mandatory"
                            name="mandatory"
                            label="Mandatory"
                          />
                        </Space>
                      </ProFormList>
                    </Space>
                    <br />
                    <br />
                  </ProForm>
                </Drawer>
              )}
            </React.Fragment>
          )
        }}
        toolBarRender={(act, rows) => {
          if (allowDelete && allowExport)
            return [
              <Button
                danger
                size="large"
                type="text"
                disabled={rows.selectedRowKeys.length < 1}
                onClick={async () => {
                  let result = await DeleteRecords(
                    dataState.viewInfo.viewId,
                    rows.selectedRows
                  )
                  if (result.status === "Success") {
                    notification.success({
                      message: "Delete Data",
                      description: result.message,
                    })
                    setReloader(!reloader)
                  } else {
                    notification.error({
                      message: "Delete Data",
                      description: result.message,
                    })
                  }
                }}
              >
                <Space>
                  <DeleteOutlined /> Delete
                </Space>
              </Button>,
              <DownloadButton viewId={params.viewId} params={dataListParam} />,
            ]
          else if (allowDelete)
            return [
              <Button
                danger
                size="large"
                type="text"
                disabled={rows.selectedRowKeys.length < 1}
                onClick={async () => {
                  let result = await DeleteRecords(
                    dataState.viewInfo.viewId,
                    rows.selectedRows
                  )
                  if (result.status === "Success") {
                    notification.success({
                      message: "Delete Data",
                      description: result.message,
                    })
                    setReloader(!reloader)
                  } else {
                    notification.error({
                      message: "Delete Data",
                      description: result.message,
                    })
                  }
                }}
              >
                <Space>
                  <DeleteOutlined /> Delete
                </Space>
              </Button>,
            ]
          else if (allowExport)
            return [
              <DownloadButton viewId={params.viewId} params={dataListParam} />,
            ]
          else return []
        }}
        editable={{
          type: "single",
          deleteText: <DeleteOutlined title="Delete This Record" />,
          saveText: <SaveOutlined title="Save Changes" />,
          cancelText: <CloseOutlined title="Cancel Edit" />,
          editableKeys: editKeys,
          actionRender: (row, config, dom) => [dom.cancel, dom.save],
          onCancel: (key, record, oriRow) => {
            setEditableKeys([])
          },
          onSave: async (keys, record) => {
            let saveResult = await EditRecords(
              dataState.viewInfo.viewId,
              record
            )
            if (saveResult.status == "Success") {
              notification.success({
                message: "Edit Data",
                description: saveResult.message,
              })
              setReloader(!reloader)
            } else {
              notification.error({
                message: "Delete Data",
                description: saveResult.message,
              })
            }
          },
          // onDelete: (keys, record) => {
          //   console.log("on delete triggered", record);
          // },

          // set onchange for uncontrolled component
          // onChange: (keys, row) => {
          //   console.log("key edited with val : ", row);
          //   console.log("key : ", keys);
          //   setEditableKeys(keys);
          // },
          // onValuesChange: (rec, dtSource) => {
          //   console.log("has changed : ", rec);
          //   console.log("ddd dsdsdsd : ", dtSource);
          // },
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {}, // click row
            onDoubleClick: (event) => {
              if (allowEdit && !props.hideEdit) setEditableKeys([record.key])
              if (props.showCustomAction) {
                setSelectedCustomer(record)
                setOpenModal(true)
              }
            }, // double click row
            onContextMenu: (event) => {}, // right button click row
            // onMouseEnter: (event) => {}, // mouse enter row
            // onMouseLeave: (event) => {}, // mouse leave row
          }
        }}
      />
    </>
  )
}

export default ListViewV2
