import React, { Component } from "react"
import { Route, Routes } from "react-router-dom"
import { Home } from "./components/Home"
import AdministratorIndex from "./pages/Administrator/AdministratorIndex"
import MenuAccess from "./pages/Administrator/MenuAccess/MenuAccess"
import UserManagement from "./pages/Administrator/UserManagement/UserManagement"
import ListViewV2 from "./pages/App/DataListV2"
import Process from "./pages/App/Process"
import Uploader from "./pages/App/Uploader"
import UploaderIndex from "./pages/App/Uploader/UploaderIndex"
import { Welcome } from "./pages/App/Welcome"
import MainLogin from "./pages/Authentication/Login"
import Logout from "./pages/Authentication/Logout"
import MainLayout from "./pages/Layouts/MainLayout"
import ListHistory from "./pages/HistoryNew/ListHistory"
import CustomerList from "./pages/Static/Customers"
import AddCustomer from "./pages/Administrator/AddCustomer/AddCustomer"

import "./pages/styles/general.css"
import "./assets/css/nucleo-svg.css"
import "./assets/css/nucleo-icons.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "./assets/scss/argon-design-system.scss?v1.0.0"
import UploadedCommentIndex from "pages/App/Custom/CustomUploadComments"
import UpdateBayar from "pages/Static/UpdateBayar"
import { UpdateAddress } from "pages/Sites/UpdateAddress"
import BalanceMutation from "pages/Administrator/BalanceMutation/BalanceMutation"

export default class App extends Component {
  static displayName = App.name

  render() {
    return (
      <Routes>
        <Route index element={<Home />} />
        <Route path="authentication/login" element={<MainLogin />} />
        <Route path="authentication/logout" element={<Logout />} />
        <Route path="users/address/:nowa" element={<UpdateAddress />} />
        <Route path="app" element={<MainLayout />}>
          <Route index element={<Welcome />} />
          {/** Custom list Route*/}
          <Route
            path="list/uploadcomments"
            element={<UploadedCommentIndex />}
          />
          {/** Custom list Route*/}
          <Route path="static/customers" element={<CustomerList />} />
          <Route path="list/updatebayar" element={<UpdateBayar />} />
          <Route path="upload" element={<UploaderIndex />} />
          <Route path="addcustomer" element={<AddCustomer />} />
          <Route path="upload/:type" element={<Uploader />} />
          <Route path="list/:viewId" element={<ListViewV2 />} />
          <Route path="hist/:viewId" element={<ListHistory />} />
          <Route path="process/:mode" element={<Process />} />
          <Route path="administrator" element={<AdministratorIndex />} />
          <Route path="administrator/access" element={<MenuAccess />} />
          <Route path="administrator/mutation" element={<BalanceMutation />} />
          <Route path="administrator/users" element={<UserManagement />} />
          <Route path="administrator/addcustomer" element={<AddCustomer />} />
        </Route>
      </Routes>
    )
  }
}
