import React, { useEffect } from "react"

export const Home = () => {
  useEffect(() => {
    window.location.href = "/authentication/login"
  })

  return <>redirecting...</>
}

export default Home

// export class Home extends Component {
//   static displayName = Home.name;

//   render() {

//     return (
//       <div>
//         <h1>Hello, There!</h1>
//         <p>
//           Welcome to ollashopping mega solution app. please be patience for
//           landing page to be implemented, urrently in development mode.
//         </p>
//         <Result
//           status={403}
//           title={"You are in development mode"}
//           subTitle="Please be patience development still in progress."
//           extra={
//             <Button type="primary" href="/authentication/login">
//               Process to login page
//             </Button>
//           }
//         />
//       </div>
//     );
//   }
// }
