import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import { Login } from "../../services/AuthenticationService"
import DemoFooter from "components/footers/DemoFooter.js"
import DefaultLoginCard from "components/cards/DefaultLoginCard"
import ApplicationStore from "../../store/ApplicationStore"

function LoginPage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)
  const setUser = ApplicationStore((state) => state.InitUser)

  const handleLogin = async (e) => {
    setLoggingIn(true)
    let loginResult = await Login(e)
    if (loginResult) {
      const user = JSON.parse(localStorage.getItem("auth-id"))
      const userMenu = JSON.parse(localStorage.getItem("user-menu"))
      setUser(user, user.token, userMenu)
      setIsAuthenticated(true)
    }
    setLoggingIn(false)
  }
  useEffect(() => {
    document.body.classList.add("login-page")
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    return function cleanup() {
      document.body.classList.remove("login-page")
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = "/app"
    }
  }, [isAuthenticated])
  return (
    <>
      <div className="section-shaped my-0 skew-separator skew-mini">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            // style={{
            //   backgroundImage:
            //     "url(" + require("assets/img/pages/photo-15.jpg") + ")",
            // }}
          />
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  <h1 className="text-white">Welcome!</h1>
                  <p className="text-lead text-white">
                    Welcome to OllashoppingDepok login page. This is your
                    gateway to accessing a personalized services and processing
                    your business. Designed with simplicity and security in
                    mind.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <section className="upper">
        <Container>
          <Col className="mx-auto" lg="5" md="8">
            <DefaultLoginCard login={handleLogin} loading={loggingIn} />
          </Col>
        </Container>
      </section>
      <DemoFooter />
    </>
  )
}

export default LoginPage
