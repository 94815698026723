import React, { useEffect, useRef, useState } from "react"
import ListViewV2 from "../App/DataListV2"
import DataListStore from "../../store/DataListStore"
import { create } from "zustand"
import { Button, Space } from "antd"
import { useParams } from "react-router-dom"
import {
  ProCard,
  ProForm,
  ProFormDatePicker,
  ProFormField,
  ProFormText,
} from "@ant-design/pro-components"
import { Container, Input, Label } from "reactstrap"

const searchHistoryDataStore = create(DataListStore)
const ListHistory = (props) => {
  const params = useParams()
  const searchForm = useRef()
  const [searched, setSearched] = useState(false)
  const [searchVals, setSearchVals] = useState([])

  useEffect(() => {
    setSearched(false)
    setSearchVals([])
  }, [params.viewId])

  return (
    <>
      <Container>
        <h3>Search History</h3>
        <ProCard>
          <Space direction="vertical">
            {params.viewId === "rekapsalesdetailhistory" && (
              <ProForm
                grid
                layout={"horizontal"}
                labelCol={{
                  md: 4,
                }}
                labelAlign="left"
                title="Search History"
                submitter={false}
                formRef={searchForm}
                onFinish={(val) => {
                  let currentFilter = []
                  if (val.Nama) {
                    currentFilter.push({
                      filterField: "Nama",
                      filterOperator: "LIKE",
                      filterValue: val.Nama,
                    })
                  }
                  if (val.NoWa) {
                    currentFilter.push({
                      filterField: "NoWa",
                      filterOperator: "LIKE",
                      filterValue: val.NoWa,
                    })
                  }
                  if (val.download_date) {
                    currentFilter.push({
                      filterField: "download_date",
                      filterOperator: "EQUALS",
                      filterValue: val.download_date,
                    })
                  }
                  setSearchVals({ filters: currentFilter })
                  setSearched(true)
                }}
              >
                <ProFormText
                  fieldProps={{
                    size: "large",
                  }}
                  name={"Nama"}
                  label="Nama"
                />
                <ProFormText
                  fieldProps={{
                    size: "large",
                  }}
                  name={"NoWa"}
                  label="No WA"
                />
                <ProFormDatePicker
                  fieldProps={{
                    size: "large",
                  }}
                  name={"download_date"}
                  label="Tanggal"
                />
              </ProForm>
            )}

            {params.viewId === "bchistory" && (
              <ProForm
                grid
                layout={"horizontal"}
                labelCol={{
                  md: 4,
                }}
                labelAlign="left"
                title="Search History"
                submitter={false}
                formRef={searchForm}
                onFinish={(val) => {
                  let currentFilter = []
                  if (val.Nama) {
                    currentFilter.push({
                      filterField: "Nama",
                      filterOperator: "LIKE",
                      filterValue: val.Nama,
                    })
                  }
                  if (val.Target) {
                    currentFilter.push({
                      filterField: "Target",
                      filterOperator: "LIKE",
                      filterValue: val.Target,
                    })
                  }
                  if (val.TglTransaksi) {
                    currentFilter.push({
                      filterField: "TglTransaksi",
                      filterOperator: "EQUALS",
                      filterValue: val.TglTransaksi,
                    })
                  }
                  setSearchVals({ filters: currentFilter })
                  setSearched(true)
                }}
              >
                <ProFormText
                  fieldProps={{
                    size: "large",
                  }}
                  name={"Nama"}
                  label="Nama"
                />
                <ProFormText
                  fieldProps={{
                    size: "large",
                  }}
                  name={"Target"}
                  label="No WA"
                />
                <ProFormDatePicker
                  fieldProps={{
                    size: "large",
                  }}
                  name={"TglTransaksi"}
                  label="Tanggal"
                />
              </ProForm>
            )}

            <Space>
              <Button
                onClick={() => {
                  searchForm.current.submit()
                }}
                size="large"
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  setSearched(false)
                  searchForm.current.resetFields()
                }}
                danger
                size="large"
              >
                Clear
              </Button>
            </Space>
          </Space>
        </ProCard>
      </Container>
      {searched && (
        <ListViewV2
          viewId={params.viewId}
          store={searchHistoryDataStore}
          filters={searchVals}
          hideFilter
        />
      )}
    </>
  )
}

export default ListHistory
