import React, { useState } from "react"
import { Button, FloatButton, Popover, Space } from "antd"
import { Outlet, useNavigate } from "react-router-dom"
import { PoweroffOutlined } from "@ant-design/icons"
import { PageContainer, ProLayout, ProCard } from "@ant-design/pro-components"
import ApplicationStore from "../../store/ApplicationStore"
import DemoFooter from "components/footers/DemoFooter"
import { Container } from "reactstrap"
import DemoNavbar from "components/navbars/DemoNavbar"

const img = require("assets/img/pages/photo-15.jpg")

const MainLayout = () => {
  const navigate = useNavigate()
  const [pathname, setPathname] = useState("/")

  const userAuth = ApplicationStore((state) => state.userAuth)
  const userMenu = ApplicationStore((state) => state.menu)
  const setMenu = ApplicationStore((state) => state.setCurrentMenu)

  return (
    <>
      <DemoNavbar type="transparent" menu={userMenu} />
      <div className="wrapper">
        <div className="section-shaped my-0 skew-separator skew-mini">
          <div className="page-header page-header-small header-filter">
            <div className="page-header-image"></div>
          </div>
        </div>
        <div className="upper" style={{ marginTop: -250 }}>
          <ProCard
            style={{
              zIndex: 10,
              minHeight: 800,
              backgroundImage:
                "url(" + require("assets/img/ill/presentation_bg.png") + ")",
            }}
          >
            <div className="pb-2">
              <Outlet />
            </div>
          </ProCard>
        </div>
        <DemoFooter />
      </div>
      {/* <ProLayout
        colorPrimary="#1A56A6"
        disableMobile
        fixedHeader
        fixSiderbar
        siderWidth={256}
        splitMenus={true}
        layout="mix"
        navTheme="light"
        siderMenuType="group"
        title={"Ollashopping"}
        menu={{
          locale: true,
          defaultOpenAll: false,
          autoClose: true,
          ignoreFlatMenu: true,
        }}
        headerRender={(props, dom) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Popover
              trigger={"click"}
              content={
                <Space wrap>
                  <ProCard
                    bordered
                    hoverable
                    bodyStyle={{ padding: "0px 8px", cursor: "pointer" }}
                  >
                    <Space wrap style={{ width: "240px" }}>
                      <i className="icofont-coins icofont-2x"></i>
                      <h4>Rekapper Application</h4>
                      <p style={{ textOverflow: "clip" }}>
                        Rekapper application for calculationg transaction
                        online.
                      </p>
                    </Space>
                  </ProCard>
                  <ProCard
                    bordered
                    hoverable
                    bodyStyle={{ padding: "0px 8px", cursor: "pointer" }}
                  >
                    <Space wrap style={{ width: "240px" }}>
                      <i className="icofont-brand-whatsapp icofont-2x"></i>
                      <h4>OSD Reactor</h4>
                      <p style={{ textOverflow: "clip" }}>
                        Reactor application for responding current transaction
                        online.
                      </p>
                    </Space>
                  </ProCard>
                </Space>
              }
            >
              <Button type="text">
                <i className="icofont-listine-dots"></i>
              </Button>
            </Popover>
            {dom}
          </div>
        )}
        route={userMenu}
        location={{
          pathname,
        }}
        avatarProps={{
          src: (
            <div style={{ background: "#A4A4A4" }}>
              <i className="icofont-architecture-alt"></i>
            </div>
          ),
          title: userAuth.firstName,
          size: "default",
          render: (_, dom) => {
            return (
              <Popover
                placement="bottomRight"
                title={
                  <div
                    style={{
                      minWidth: "246px",
                      textAlign: "center",
                    }}
                  >
                    <Space direction="vertical">
                      {userAuth.firstName + " " + userAuth.lastName}
                      <i className="icofont-brand-myspace icofont-4x"></i>
                      {userAuth.accessName}
                    </Space>
                    <hr />
                  </div>
                }
                content={
                  <Button
                    type="link"
                    onClick={() => {
                      navigate("/authentication/logout", {
                        state: {
                          local: true,
                        },
                      })
                    }}
                  >
                    <Space>
                      <PoweroffOutlined />
                      <span>Log Out</span>
                    </Space>
                  </Button>
                }
                trigger="hover"
              >
                {dom}
              </Popover>
            )
          },
        }}

        menuItemRender={(item, dom) => {
          return (
            <div
              onClick={() => {
                setMenu(item)
                setPathname(item.path)
                if (item.link) {
                  navigate(item.link)
                }
              }}
            >
              {dom}
            </div>
          )
        }}
        menuFooterRender={(props) => {
          if (props?.collapsed) return undefined
          return (
            <div
              style={{
                textAlign: "center",
                paddingBlockStart: 12,
              }}
            >
              <hr />
              <div>©{new Date().getFullYear()} OSD.</div>
              <div>Ollashopping Depok Rekaper.</div>
            </div>
          )
        }}
      >
        <div className="wrapper">
          <PageContainer
            breadcrumbRender={(props, dom) => {
              if (props.currentMenu) {
                var menuPath = props.currentMenu.locale?.split(".") || []
                menuPath = menuPath.filter((m) => m !== "menu")
                return (
                  <span style={{ color: "rgba(40,40,40,0.8)" }}>
                    {menuPath.reduce((p, n) => {
                      return p + " / " + n
                    }, "")}
                  </span>
                )
              } else {
                return <span></span>
              }
            }}
          >
            <FloatButton.BackTop duration={100} style={{ bottom: 100 }} />
            <Outlet />
          </PageContainer>
          <DemoFooter />
        </div>
      </ProLayout> */}
    </>
  )
}

export default MainLayout
