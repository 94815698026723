import {
  getInfo,
  requestDataWithCommand,
} from "../services/datalist/datalist-api"

const INITIAL = {
  loading: true,
  viewId: null,
  dataListParam: null,
  dataListState: null,
  currentEditableKeys: [],
  currentKeyword: null,
  currentFilters: [],
}

let DataListStore = (set, get) => ({
  ...INITIAL,

  updateKeyword: (currentKeyword) => set((state) => ({ currentKeyword })),
  updateFilters: (currentFilters) => set((state) => ({ currentFilters })),
  visibleColumns: () =>
    get()
      ?.dataListState?.columns.filter((c) => c.showInList)
      .sort((a, b) => a.columnOrder - b.columnOrder) || [],

  initState: async (viewId, filters) => {
    set((state) => ({
      ...INITIAL,
      currentFilters: filters ?? [],
      loading: true,
      viewId,
    }))
    let _state = await getInfo(viewId)
    set((state) => ({ loading: false, dataListState: _state }))
  },
  setLoading: (loading) => set((state) => ({ loading })),
  setEditableKeys: (newKeys) =>
    set((state) => ({ currentEditableKeys: newKeys })),
  requestData: async (param, sort, filter) => {
    let commandParam = {
      pageSize: param.pageSize,
      current: param.current,
      param,
      sort,
      filters: param.filter.filters || [],
      keyword: param.currentKeyword,
    }
    set((state) => ({ dataListParam: commandParam }))
    let resultCommand = await requestDataWithCommand(get().viewId, commandParam)

    return {
      data: resultCommand.records,
      success: true,
      total: resultCommand.total,
    }
  },
})

export default DataListStore
