import { Button, Space } from "antd"
import axios from "axios"
import { useState } from "react"

const { ProForm, ProFormSelect, ProFormDatePicker, ProCard, ProTable } = require("@ant-design/pro-components")


const axs = axios.create({
    baseURL: "https://production.ollashoppingdepok.com"
})


const BalanceMutation = () => {

    const [dataSource, setDataSource] = useState([])
    const [csvFile, setCsvFile] = useState()

    const [loading, setLoading] = useState(false)

    return <Space direction="vertical" style={{ width: "100%" }} >
        <ProCard title="Mutasi Rekening">
            <ProForm
                onReset={() => {
                    setDataSource([])
                    setCsvFile("")
                }}
                onFinish={async (val) => {
                    setLoading(true)

                    let payload = {
                        "accountNo": val.account,
                        "fDateTime": val.fDate,
                        "tDateTime": val.tDate
                    }

                    const { data } = await axs.post("/openapi/v1/AccountBCA/BankStatement", payload, {})
                    // const data = mockData

                    let csvContent = "data:text/csv;charset=utf-8,No|Tanggal|Type|Remark|Balance|Jumlah\r\n";
                    let dataarray = []
                    if (data.responseCode == "2001400") {
                        for (let i = 0; i < data.detailData.length; i++) {
                            let trx = data.detailData[i]
                            let bal = data.balance[i]
                            dataarray.push({
                                no: i,
                                date: trx.transactionDate.split("T")[0],
                                type: trx.type,
                                remark: trx.remark,
                                balance: bal.amount.value,
                                amount: trx.amount.value
                            })
                            csvContent += i + "|" + trx.transactionDate.split("T")[0] + "|" + trx.type + "|" + trx.remark + "|" + bal.amount.value + "|" + trx.amount.value + "\r\n"
                        }
                    }
                    setCsvFile(csvContent)
                    setDataSource(dataarray)
                    setLoading(false)
                }}>
                <ProFormSelect width={"lg"} name={"account"} label="No. Rekening" options={[{ label: "BCA - 5540776999", value: "5540776999" }]} rules={[{ required: true }]} />
                <ProFormDatePicker width={"lg"} name="fDate" label="Dari Tanggal" rules={[{ required: true }]} />
                <ProFormDatePicker width={"lg"} name="tDate" label="Ke Tanggal" rules={[{ required: true }]} />
            </ProForm>
        </ProCard>


        <ProTable
            tableExtraRender={() => <Space style={{ display: "flex", justifyContent:"flex-end" }}>
                <Button disabled={!csvFile} type="primary" onClick={() => {
                    var encodedUri = encodeURI(csvFile);
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "Mutasi - 5540776999.csv");
                    document.body.appendChild(link); // Required for FF

                    link.click(); // This will download the data file named "my_data.csv".
                }}>Download Mutasi</Button>
            </Space>}
            loading={loading}
            search={false} options={false} rowKey={"no"} dataSource={dataSource} columns={[
                {
                    key: "date",
                    dataIndex: "date",
                    title: "Tanggal",
                },
                {
                    key: "type",
                    dataIndex: "type",
                    title: "Tipe",
                },
                {
                    key: "remark",
                    dataIndex: "remark",
                    title: "Remark",
                },
                {
                    key: "balance",
                    dataIndex: "balance",
                    title: "Balance",
                    valueType: "digit",
                    align: "right"
                },
                {
                    key: "amount",
                    dataIndex: "amount",
                    title: "Jumlah",
                    valueType: "digit",
                    align: "right"
                },

            ]}></ProTable>
    </Space>
}

export default BalanceMutation