import React, { useState } from "react"
import { ProCard, ProTable } from "@ant-design/pro-components"
import { getRequest } from "services/EndPointApiService"
import { Modal, Space } from "antd"
import { Container } from "reactstrap"

export function Welcome() {
  const [loading, setLoading] = useState(true)
  const [childLoading, setChildLoading] = useState(true)
  const [salesData, setSalesData] = useState([])
  const [selectedPayment, setSelectedPayment] = useState([])
  const [openModal, setOpenModal] = useState(false)

  React.useEffect(() => {
    setLoading(true)
    getRequest("Authentication/GetDashboardReport").then((res) => {
      setSalesData(res.sales)
      setLoading(false)
    })
  }, [])
  return (
    <ProCard
      style={{
        padding: 0,
        margin: 0,
        backgroundColor: "rgba(255,255,255,0.7)",
      }}
      bodyStyle={{
        padding: 0,
        margin: 0,
        backgroundColor: "rgba(255,255,255,0.7)",
      }}
    >
      <Modal
        destroyOnClose
        open={openModal}
        width={"60vw"}
        style={{ minWidth: "450px" }}
        onCancel={() => {
          setOpenModal(false)
        }}
        onOk={() => {
          setOpenModal(false)
        }}
      >
        <h2>Pembayaran Detail</h2>
        <ProTable
          rowKey={"Id"}
          search={false}
          scroll={{ x: "max-content" }}
          loading={childLoading}
          pagination={{
            defaultPageSize: 10,
            showQuickJumper: true,
          }}
          columns={[
            { dataIndex: "nama", key: "nama", title: "Nama" },
            {
              dataIndex: "bayar",
              key: "bayar",
              title: "Pembayaran",
              valueType: "digit",
              align: "right",
            },
          ]}
          dataSource={selectedPayment}
        />
      </Modal>
      <Container>
        <div className="wrapper">
          <h2>Report Sales vs Payment</h2>
          <Space direction="vertical" style={{ width: "100%" }}>
            <ProTable
              loading={loading}
              rowKey={"Id"}
              search={false}
              scroll={{ x: "max-content" }}
              headerTitle={
                <div>
                  {loading && <p>Please Wait, Generating Report...</p>}
                  {!loading && <p>Click on data to see details</p>}
                </div>
              }
              pagination={{
                defaultPageSize: 10,
                showQuickJumper: true,
              }}
              columns={[
                {
                  title: "Tanggal",
                  dataIndex: "Tanggal",
                  key: "tanggal",
                  valueType: "date",
                },
                {
                  title: "Total BC",
                  dataIndex: "TotalBC",
                  key: "totalBc",
                  valueType: "digit",
                  align: "right",
                },
                {
                  title: "Total Tagihan",
                  dataIndex: "TotalTagihan",
                  key: "totalTagihan",
                  valueType: "digit",
                  align: "right",
                },
                {
                  title: "Total Tagihan + Ongkir",
                  dataIndex: "TotalTagihanPlusOngkir",
                  key: "totalTagihanPlusOngkir",
                  valueType: "digit",
                  align: "right",
                },
                {
                  title: "Total Pembayaran",
                  dataIndex: "TotalBayar",
                  key: "totalPayment",
                  valueType: "digit",
                  align: "right",
                },
                {
                  title: "Persentase Bayar",
                  dataIndex: "PersentaseBayar",
                  key: "PersentaseBayar",
                  valueType: "percent",
                  align: "right",
                },
              ]}
              dataSource={salesData}
              onRow={(data, _) => {
                return {
                  onClick: async () => {
                    setChildLoading(true)
                    setOpenModal(true)
                    let payment = await getRequest(
                      "Authentication/GetReportDetail/" + data.Id
                    )
                    setSelectedPayment(payment.map((p, i) => ({ ...p, Id: i })))
                    setChildLoading(false)
                  },
                }
              }}
            />
          </Space>
        </div>
      </Container>
    </ProCard>
  )
}

export default Welcome
