import { ProCard, ProForm, ProFormText } from "@ant-design/pro-components"
import { notification, Space } from "antd"
import React from "react"
import { postRequest } from "../../services/EndPointApiService"
import ApplicationStore from "../../store/ApplicationStore"
import { Container } from "reactstrap"

const Process = () => {
  const userAuth = ApplicationStore((state) => state.userAuth)
  return (
    <Container>
      <ProCard
        style={{ width: "65vw", minWidth: "250px" }}
        hoverable
        bordered
        title={
          <Space>
            <i className="icofont-license icofont-2x"></i>
            <h3>Process current transaction</h3>
          </Space>
        }
      >
        <ProForm
          title="Process Current transaction"
          onFinish={async (values) => {
            if (userAuth.allowProcess)
              return postRequest("process/transaction", values)
            else {
              notification.error({
                message: "Process Trnsaction",
                description:
                  "You dont have permission to do this. Please contact your administrator.",
              })
            }
          }}
        >
          <ProFormText
            fieldProps={{
              size: "large",
            }}
            label="Transaction Name"
            name={"transactionType"}
          />
        </ProForm>
      </ProCard>
    </Container>
  )
}

export default Process
