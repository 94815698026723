import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js"
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Row,
  Col,
  Button,
} from "reactstrap"
import { Space } from "antd"

function DemoNavbar(props) {
  const [collapseOpen, toggleCollapse] = React.useState(false)

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("dark-navbar-main"))
    // initialise
    headroom.init()
  }, [])

  let navbarType = ""
  switch (props.type) {
    case "dark":
      navbarType = "bg-default navbar-dark"
      break
    case "transparent":
      navbarType = "navbar-transparent"
      break
    case "primary":
      navbarType = "bg-primary navbar-dark"
      break
    case "white":
      navbarType = "bg-white"
      break
    default:
      navbarType = "bg-default navbar-dark"
      break
  }
  return (
    <>
      <Navbar
        className={"navbar-main headroom " + navbarType}
        expand="lg"
        id="dark-navbar-main"
      >
        <NavbarBrand className="mr-lg-5" to="/app" tag={Link}>
          <div style={{ fontSize: 24 }}>
            <strong>OLS</strong>
          </div>
          Ollashopping Depok
        </NavbarBrand>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => toggleCollapse(!collapseOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Collapse
          id="navbar_global"
          navbar
          toggler="#navbar_global"
          isOpen={collapseOpen}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/app">
                  <div style={{ fontSize: 24 }}>
                    <strong>OLLASHOPING</strong>
                  </div>
                </Link>
              </Col>
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  onClick={() => toggleCollapse(!collapseOpen)}
                >
                  <span></span>
                  <span></span>
                </button>
              </Col>
            </Row>
          </div>
          <Nav
            className="navbar-nav-hover align-items-lg-center ml-lg-auto"
            navbar
          >
            {props.menu?.routes?.map((r) => {
              return (
                <UncontrolledDropdown nav key={r.key}>
                  <DropdownToggle
                    data-toggle="dropdown"
                    href={"#" + r.key}
                    onClick={(e) => e.preventDefault()}
                    role="button"
                    tag={NavLink}
                  >
                    <i className="ni ni-ui-04 d-lg-none"></i>
                    <span className="nav-link-inner--text">{r.name}</span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-xl">
                    {r.routes?.map((rr) => {
                      return (
                        <DropdownItem key={rr.key} tag={Link} to={rr.link}>
                          <i className="ni ni-align-center text-info"></i>
                          {rr.name}
                        </DropdownItem>
                      )
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )
            })}
            <Button
              onClick={() => {
                window.location.href = "authentication/logout"
              }}
            >
              <Space>
                Logout
                <i className="ni ni-button-power"></i>
              </Space>
            </Button>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  )
}

DemoNavbar.defaultProps = {
  type: "dark",
}

DemoNavbar.propTypes = {
  type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
}

export default DemoNavbar
