import React, { useEffect, useState } from "react";
import { create } from "zustand";
import DataListStore from "../../store/DataListStore";
import ListViewV2 from "../App/DataListV2";
import { PembayaranLunas } from "services/datalist/datalist-api";
import { notification } from "antd";

const defaultDataStore = create(DataListStore);

const UpdateBayar = (props) => {
    const [reload, setReload] = useState(false)

  return (
    <ListViewV2
      viewId={'updatebayar'}
      store={defaultDataStore}
      reloader={reload}
      showUpdateAction
      onUpdateAction={row =>{
        console.log('row : ', row)
        PembayaranLunas(row.SesionID, row.No).then(res => {
            notification.success({
                message: "Update Pembayaran",
                description: "Rekap Pembayaran berhasil di update"
            })
            setReload(!reload)
        })
      }}
      hideEdit
    />
  );
};

export default UpdateBayar;
