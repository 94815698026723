import { persist } from "zustand/middleware";
import { create } from "zustand";

let ApplicationStore = (set, get) => ({
  userAuth: null,
  menu: null,
  token: null,
  currentMenu: {},

  setCurrentMenu: (currentMenu) => set((state) => ({ currentMenu })),
  InitUser: (userAuth, token, menu) =>
    set((state) => ({ userAuth, token, menu })),
});

ApplicationStore = persist(ApplicationStore, {
  name: "app-context",
});
ApplicationStore = create(ApplicationStore);

export default ApplicationStore;
