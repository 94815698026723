import { getRequest, postRequest } from "../EndPointApiService";

export async function getInfo(viewId) {
  return await getRequest("DataList/GetInfo/" + viewId);
}

export async function requestDataWithCommand(viewId, command) {
  return await postRequest("DataList/RequestDataCommand/" + viewId, command);
}

export async function UpdateViewSetting(viewId, command) {
  return await postRequest("DataList/UpdateSetting/" + viewId, command);
}

export async function DeleteRecords(viewId, records) {
  return await postRequest("DataList/DeleteRecords/" + viewId, records);
}

export async function EditRecords(viewId, record) {
  return await postRequest("DataList/EditRecord/" + viewId, record);
}

export async function UpdateWACustomer(fbid, noWA) {
  return await postRequest("DataList/UpdateWACustomer/" + fbid + "/" + noWA);
}

export async function DeleteTransaction(sid) {
  return await postRequest("DataList/DeleteTransaction/" + sid);
}

export async function PembayaranLunas(sid, no){
  return await postRequest("DataList/PembayaranLunas/"+sid+"/"+no);
}

export async function downloadTemplate(fileName) {
  return getRequest("DataList/DownloadTemplate/" + fileName, {
    observe: "response",
    responseType: "blob",
  }).then((resultDownload) => {
    const a = document.createElement("a");
    a.setAttribute("style", "display:none;");
    document.body.appendChild(a);
    const file = new Blob([resultDownload]);
    a.download = fileName;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, fileName);
    } else {
      a.href = URL.createObjectURL(file);
    }
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
  });
}
