import { ProForm, ProFormSelect, ProFormText } from "@ant-design/pro-components"
import { useEffect, useState } from "react"
import {
  AddCustomerFromUnknown,
  GetUnknownCustomerList,
} from "../../../services/AdministrativeService"
import { notification } from "antd"

const AddCustomer = () => {
  const [form] = ProForm.useForm()
  const [unknownCustList, setUnknownCustList] = useState([])
  const [loading, setLoading] = useState(false)
  const [reloader, setReloader] = useState(false)

  useEffect(() => {
    GetUnknownCustomerList().then((res) => {
      let list = res || []
      if (list.length > 0) {
        list = [...new Map(list.map((item) => [item["fbid"], item])).values()]
        setUnknownCustList(
          list.map((c) => ({
            value: c.id,
            label: c.fbid + " - " + c.fbName,
          }))
        )
      }
    })
  }, [reloader])

  return (
    <ProForm
      form={form}
      loading={loading}
      submitter={{
        submitButtonProps: {
          size: "large",
        },
        resetButtonProps: {
          size: "large",
        },
      }}
      onFinish={async (val) => {
        setLoading(true)
        let result = await AddCustomerFromUnknown(val)
        if (result.status === "Success") {
          notification.success({
            message: "Add Customer",
            description: result.message,
          })
          setReloader(!reloader)
          form.resetFields()
        } else {
          notification.error({
            message: "Add Customer",
            description: result.message,
          })
        }
        setLoading(false)
      }}
    >
      <ProFormSelect
        showSearch
        fieldProps={{
          size: "large",
        }}
        name="unknownCustomerId"
        label="Select Customer (*from unknown customer)"
        options={unknownCustList}
      />
      <ProFormText
        fieldProps={{
          size: "large",
        }}
        name="phoneNumber"
        label="Add Phone Number"
      />
    </ProForm>
  )
}

export default AddCustomer
